'use strict';
import { app } from '@/main.js'

const AuthService = {
  ssoGoogle(usertoken) {
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios.post('/security/loginGoogleSSO', { usertoken: usertoken})
        .then(response => {
          console.log("resolve login: %O", response);
          resolve(response);
        })
        .catch(error => {
          console.log("error catcher login %O", error.response.data);
          reject(error.response.data);
        })
    })
  },
  saveUserLocal(user) {
    return new Promise((resolve, reject) => {
      app.config.globalProperties.$axios.post('/saveUserLocal', user)
        .then(response => {
          console.log("resolve login: %O", response);
          resolve(response);
        })
        .catch(error => {
          console.log("error catcher login %O", error.response.data);
          reject(error.response.data);
        })
    })
  },
}
export default AuthService;

