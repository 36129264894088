import { createStore } from 'vuex';

function getValueFronSession(value, returnDefault) {
    if (sessionStorage.getItem(value) != null && sessionStorage.getItem(value) != 'undefined') {
        return JSON.parse(sessionStorage.getItem(value))
    }
    else
        return (returnDefault)
}

export default createStore({
    state: {
        User: getValueFronSession('user', null),
        weigths: [],
        baseApi: '/api'
    },
    getters: {
        getBaseApi(state) {
            return state.baseApi;
        },
        getWiegths(state) {
            return state.weigths;
        },
        setLogOut(state) {
            sessionStorage.removeItem("user");
            state.User = null;
        },
        getUserLogin(state) {
            return state.User;
        },
        isAuth(state) {
            if (state.User)
                return true;
            else
                return false;
        }
    },
    mutations: {
        setUserLogin(state, user) {
            sessionStorage.setItem("user", user);
            state.User = user;
        }
    }
})
