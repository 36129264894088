'use strict';
import { app } from '@/main.js'

const PlantsService = {

    plantsInClusterPlant(plant){
        return new Promise((resolve, reject) => {

            app.config.globalProperties.$axios.get(`/masterData/plantsInClusterPlant/${plant}`)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });

        });
    }

}

export default PlantsService;