import { createApp } from "vue";
import store from './store/index';
import PrimeVue from 'primevue/config';
import vSelect from 'vue-select'
import ToastService from 'primevue/toastservice';
import Notifications from '@kyvg/vue3-notification'
import { LoadingPlugin } from 'vue-loading-overlay';
import ConfirmationService from 'primevue/confirmationservice';
import 'vue-loading-overlay/dist/css/index.css';
import { ApmVuePlugin } from '@elastic/apm-rum-vue' 
const dotenv = require('dotenv');
const path = require('path');
dotenv.config({ path: path.resolve(__dirname, '../conf/application.secrets') });

import App from "./App.vue";
import router from "./router";

import axios from 'axios'
const MOMENT = require("moment");
const MOMENT_RANGE = require("moment-range");
const moment = MOMENT_RANGE.extendMoment(MOMENT);

let baseApi = '/api';

let axiosBase = axios.create({ baseURL: baseApi, retry : 3, retryDelay: 100 });

axiosBase.interceptors.request.use(
  config => {

    var u = sessionStorage.getItem("user");
    if (u != null) {
      var dataUser = JSON.parse(sessionStorage.getItem("user"));

      config.headers.Authorization = dataUser.token;
      config.headers.timestamp = moment().format("YYYY-MM-DDTHH:mm:ss");
      config.headers.sales_org = localStorage.getItem('salesArea');
      config.headers.id_country = dataUser.id_country
      config.headers.country = dataUser.country.code

    }

    return config;
  },
  error => Promise.reject(error)
);


export const app = createApp(App, {
  isCustomElement : tag => tag.startsWith('lh-')  
});
app.config.globalProperties.$axios = axiosBase;
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('lh-') // (return true)
}
// app.component('DataTable', DataTable);
import languagesService from "@/services/api/languages";
import { createI18n } from 'vue-i18n'
const messages = {
  en: {
    Sign_in: 'Sign in',
    User_name: 'User name',
    password: 'password'
  },
}

let languageSelected = JSON.parse(localStorage.getItem('languageSelected'));
const i18n = createI18n({
  locale: languageSelected || 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  debug: true,
  allowComposition: true,
  globalInjection : true,
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

export { i18n };

function getLanguages(){
  languagesService.getTraductions(languageSelected || 'en_gb').then(response =>{
    let traductions = response.data;
    for(let lang in traductions){
      let element = traductions[lang]      
      i18n.global.setLocaleMessage(lang, element)
    }
  })
}
getLanguages()
function getConfig(){
  let url = '/getConfig'
  app.config.globalProperties.$axios.get(url)
  .then(response => {
    app.config.globalProperties.$config = response.data
    app.use(ApmVuePlugin, {
      router,
      config : {
        serviceName : 'EXT-FRONTAL',
        secretToken : response.data.apm.APM_TOKEN,
        serverUrl : response.data.apm.APM_URL,
        environment :  response.data.apm.APM_ENV
      }
    })
  })
  .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
}
getConfig()
//ONLY FOR TEST DEV ENV

app.use(store)
app.use(i18n)
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.use(Notifications);
app.use(LoadingPlugin)
app.use(ConfirmationService);
app.component("v-select", vSelect)
app.directive('lhevent', {
  beforeMount(el, { arg, value }) {
    el.addEventListener(arg, value)
  },
  beforeUnmount(el, { arg, value }) {
    el.removeEventListener(arg, value)
  }
})

app.mount("#app");
