<script setup>
import { RouterView } from "vue-router";
import Login from "@/views/Login.vue";
</script>
<script>
export default {
  data() {
    return {
      isLoggedIn: false,
      menuIsOpened: false,
    };
  },
  beforeMount() {
    this.isLoggedIn = this.$store.getters.isAuth;
  },
};
</script>


<template>
  <div class="app-wrapper" :class="{ 'menu-is-opened': menuIsOpened }">
    <notifications width="600px" />
    <Login v-if="!isLoggedIn" />
    <RouterView v-if="isLoggedIn" />
  </div>
</template>

<style lang="scss">
@use "./assets/css/index.scss";
</style>
