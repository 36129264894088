'use strict';
import { app } from '@/main.js'

const LanguagesService = {
    getTraductions(code){
        return new Promise((resolve, reject) => {
            let url = '/masterData/traductions/' + code;
            app.config.globalProperties.$axios.get(url)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getLanguages(){
        return new Promise((resolve, reject) => {
            let url = '/masterData/languages';
            app.config.globalProperties.$axios.get(url)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getLanguagesByCountry(country) {
        console.log("getLanguagesByCountry %O %s", country, typeof country);
        console.log(country.id_country);
        return new Promise((resolve, reject) => {
            let url = '/masterData/languages/' + country.id_country;
            console.log("url: %O", url);
            app.config.globalProperties.$axios.get(url)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    }

}

export default LanguagesService;