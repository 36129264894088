<template>
  <div class="new-login-external">

    <div class="header"> <!-- Header -->
      <img src="img/logo.svg" alt class="logo" />
      <div class="gradient"></div>
    </div>

    <div class="body"> <!-- Body -->

      <div class="row login">
        <div class="container">
          <div class="login__container">
            <form> 
              <p class="title">{{ $t('Welcome to Rocks') }}</p>
              <button @click="loginGoogleSSO" class="btn btn--primary btn--large" :disabled="inLogin"
                style="width: 100%; margin-top: 20px;">{{ $t('Login Using Google') }}</button>
            </form>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import AuthService from "@/services/api/auth";
import languagesService from "@/services/api/languages";
import Button from 'primevue/button';
import PlantsService from '@/services/api/plants';
import { googleSdkLoaded } from "vue3-google-login";
import { app } from '@/main.js';

export default {

  components: {
    Button
  },

  data: function () {
    return {
      inheritAttrs: false,
      inLogin: false,
      message: ""
    };
  },

  mounted() {
    sessionStorage.removeItem("user");
    this.$store.commit("setLogOut");
    var vm = this;
    setTimeout(() => {
      vm.loading = false;
    }, 5000);

  },

  methods: {

    loginGoogleSSO() {
      this.inLogin = true;

      googleSdkLoaded((google) => {
        google.accounts.oauth2.initCodeClient({
          client_id: app.config.globalProperties.$config.api.GOOGLE_SSO_CLIENT,
          scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
          callback: (response) => {

            AuthService.ssoGoogle(response.code).then(async (response) => {
              let vm = this;
              this.inLogin = false;

              let code = response.data.ret;
              if (code == -1) {
                vm.message = this.$i18n.t("Unknown user");
              } else {

                let user = response.data;

                user.accessApps = [];

                localStorage.setItem('numberFormat', JSON.stringify("de-DE"));
                localStorage.setItem('dateFormat', JSON.stringify({ 'calendar': 'dd/mm/yy', 'moment': 'DD/MM/YYYY' }));
                const terminal = localStorage.getItem('terminal');
                if (terminal !== undefined || terminal !== null) user.terminal = JSON.parse(terminal);
                this.$store.commit("setUserLogin", JSON.stringify(user))

                const plantsInCluster = await PlantsService.plantsInClusterPlant(user.plant.id);
                user.plantsInCluster = plantsInCluster.data;
                this.$store.commit("setUserLogin", JSON.stringify(user))

                languagesService.getLanguagesByCountry(user.country).then(response => {
                  let langs = response.data
                  for (const key in langs) {
                    let element = langs[key]

                    vm.$i18n.setLocaleMessage(key, element)
                    vm.$i18n.locale = "ES"
                  }
                  location.href = (user.plants.length == 1 && user.accessApps.length == 1) ? "/" : "/select-plant-app";
                })

                AuthService.saveUserLocal(user);

              }

            }).catch(err => {
              this.inLogin = false
              console.log("error ==> ", err)
              this.$notify({
                title: this.$t("Login User"),
                text: this.$t(`${err}`),
                type: 'error',
                duration: 10000
              });
            })
          }
        }).requestCode()
      })
    }
  },
};
</script>

<style lang="scss">
@import '@/assets/css/lh-ds/core/functions';
@import '@/assets/css/hodim/layout/data-list';
@import '@/assets/css/hodim/layout/data-list/ordercard';
@import '@/assets/css/hodim/layout/data-list/multiselect';
@import '@/assets/css/hodim/layout/data-list/calendar';
@import '@/assets/css/lh-ds/core/icon';
@import '@/assets/css/lh-ds/core/color';
@import '@/assets/css/lh-ds/core/font';
@import '@/assets/css/hodim/core/mixin';
@import "@/assets/css/hodim/layout/data-list/datatable";
@import "@/assets/css/hodim/layout/data-list/primevuecss";

.app-wrapper:has(.login) {
  height: 100vh;
}

.new-login-external {
  width: 100%;
  height: 100%;
  position: absolute;

  .header {

    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;

    .logo {
      margin-left: 3%;
      height: 40%;
    }

    .gradient {

      position: absolute;
      left: 0%;
      top: 10%;
      display: block;
      width: 100%;
      height: 0.375rem;
      background: linear-gradient(270deg, #1d4370, #04bbf1 36.98%, #04bbf1 52.08%, #94c12e 84.9%);

    }

  }

  .body {

    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("~@/assets/rocks_login_picture.webp");
    background-size: cover;

    .login__container {

      max-width: 400px;
      margin: 40px auto;
      border-radius: 8px;
      padding: 40px;
      background-color: white;

      .title {

        font-weight: bolder;
        font-size: 1.3rem;
        color: rgb(29, 67, 112);
        margin-bottom: 26px;

      }

    }

  }

}

p {
  margin: 0;
}

.login {

  &__msg {
    font-size: calculate-rem(14px);

    &:last-of-type {
      margin-top: 35px;
    }

    a {
      text-decoration: underline;
    }
  }

  &__checkbox {
    margin: 26px 0 16px;
  }
}
</style>
